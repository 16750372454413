$( document ).ready(function() {
    $(".js-input").mask("+7(999)999-99-99");

    $('.js-select').multiselect({
        nonSelectedText: 'Выберите упаковку',
        nSelectedText: ' выбрано'
    });
    $('.js-select2').multiselect({
        nonSelectedText: 'Тип чернил',
        nSelectedText: ' выбрано'
    });

    $(".multiselect").on('click', function(event){
        $(this).parent().toggleClass('open');
    });

    $(".js-toggler").on('click', function(event){
        $('.header').toggleClass('open');
    });


    $(window).on('load resize', function() {
        if ($(window).width() < 991) {
            $('.av .row').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                variableWidth: true
            });

        }
    });





    ymaps.ready(init);
    function init () {
        var myMap1 = new ymaps.Map("map1", {
            center: [55.77101400, 37.63209300],
            zoom: 13,
            controls: ["zoomControl"]
        });

        myMap1.controls.add('fullscreenControl', {float: 'left'});
        myMap1.geoObjects
            .add(new ymaps.Placemark([55.77101400, 37.63209300], {
                balloonContent: 'Ул. Уличная,д. 22'
            }, {
                iconLayout: 'default#image',
                iconImageHref: 'assets/images/svg/pinmap.svg',
                iconImageSize: [64, 64],
                iconImageOffset: [-80, -70]
            }));

        var myMap2 = new ymaps.Map("map2", {
            center: [55.77101400, 37.63209300],
            zoom: 13,
            controls: ["zoomControl"]
        });

        myMap2.controls.add('fullscreenControl', {float: 'left'});
        myMap2.geoObjects
            .add(new ymaps.Placemark([55.77101400, 37.63209300], {
                balloonContent: 'Ул. Уличная,д. 22'
            }, {
                iconLayout: 'default#image',
                iconImageHref: 'assets/images/svg/pinmap.svg',
                iconImageSize: [64, 64],
                iconImageOffset: [-80, -70]
            }));

    }


});
